<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('knowledge.feedback')" class="item_inbox feedback">
    <CCol cols="3" lg="3" md="12">
      <CCard class="h-100 mb-0">
        <CCardHeader>
          <span><i class="fas fa-question-circle mr-1"/>{{$t('kb.feedback')}} ({{feedback.length}})</span>
        </CCardHeader>
        <CCardBody class="items pb-0">
          <CRow class="h-100">
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <CRow class="flex-grow-0">
                <CCol cols="12" lg="12" class="pt-0">
                  <div class="mb-2">
                    <label class="mb-1"><strong>{{$t('knowledge.Feedback_status')}}</strong></label>
                    <multiselect class="data_table"
                                 v-model="selectedStatuses" 
                                 :options="feedbackStatuses" 
                                 :multiple="true"
                                 :hideSelected="false"
                                 :closeOnSelect="true"
                                 :placeholder="$t('common.filter_by_status')"
                                 :selectLabel="$t('common.Add_status')"
                                 track-by="tag" 
                                 label="label"
                                 @input="getFeedback();">
                      <span slot="noResult">{{$t('common.no_statuses_found')}}</span>
                    </multiselect>
                  </div>
                  <div class="mb-2">
                    <label class="mb-1"><strong>{{$t('knowledge.Feedback_time_range')}}</strong></label>
                    <CSelect :placeholder="$t('knowledge.Feedback_select_time_range')" :value.sync="timeRange" :options="timeRangeSelect" @update:value="getFeedback();"/>
                  </div>
                  <div class="d-flex align-items-center">
                    <b-switch class="m-0" v-model="showMyFeedbackItems" size="is-small" @input="getFeedback()">{{ $t('knowledge.Show_my_items') }}</b-switch>
                    <i class="icon fas fa-question-circle help_icon" v-c-tooltip="{placement: 'right', content: $t('knowledge.My_items_help2')}"/>
                  </div>
                </CCol>
              </CRow>
              <CRow class="item_container add_top_border">
                <CCol cols="12" lg="12" :class="{ 'pt-0 pb-0' : feedback.length > 0 }">
                  <div v-if="feedback.length > 0">
                    <CRow class="item" 
                          v-for="feedbackItem in feedback" 
                          v-bind:key="feedbackItem.kb_feedback_id_external" 
                          v-bind:class="{active: feedbackItem.kb_feedback_id_external === activeFeedbackItem.kb_feedback_id_external}" 
                          @click="getFeedbackDetails(feedbackItem.kb_feedback_id_external, feedbackItem.kb_intent_id_external);">
                      <CCol cols="12" lg="12">
                        <div class="item_tags mb-2">
                          <b-tag v-bind:class="{'detail' : feedbackItem.feedback_status === 'open', 'success' : feedbackItem.feedback_status === 'done', 'warning' : feedbackItem.feedback_status === 'rejected'}">
                            {{$t('knowledge.feedback_' + feedbackItem.feedback_status)}}
                          </b-tag>
                          <b-tag class="info">{{$t('knowledge.feedback_' + feedbackItem.feedback_type)}}</b-tag>
                        </div>
                        <div v-if="feedbackItem.kb_item_title" class="mb-1">
                          <p v-line-clamp="1" class="m-0"><b>{{feedbackItem.kb_item_title}}</b></p>
                        </div>
                        <div class="meta d-flex align-items-center">
                          <div class="pr-1">
                            <div v-if="feedbackItem.feedback_creator !== null && feedbackItem.feedback_creator_profile_image !== null" class="profile_image d-inline-block align-middle" v-bind:style="{ backgroundImage: 'url(' + apiBaseUrl + '/v1/common/cdn/file/image/core-user-profile/' + feedbackItem.feedback_creator + '/' + feedbackItem.feedback_creator_profile_image + '/' + clientToken + ')' }"></div>
                            <userProfileAvatar v-else-if="feedbackItem.feedback_creator_name" :username="feedbackItem.feedback_creator_name" :size="20"/>
                          </div>
                          <p v-line-clamp="1" class="mb-0">{{feedbackItem.feedback_creator_name}} - {{feedbackItem.created | moment("YYYY-MM-DD HH:mm")}} - {{feedbackItem.message}}</p>
                        </div>
                      </CCol>
                    </CRow>
                  </div>
                  <div v-else>
                    <span>{{$t('knowledge.No_feedback_found_with_these_filters')}}.</span>
                  </div>                                  
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>

    <CCol cols="6" lg="6" md="12">
      <CCard class="h-100 mb-0">
        <CCardHeader>
          <span>{{ $t('knowledge.Feedback_details') }}</span>
        </CCardHeader>
        <CCardBody class="pt-0 pb-0">
          <hr class="m-0">
          <CRow class="h-100">            
            <CCol cols="12" lg="12" class="h-100">
              <div v-if="activeFeedbackItem.kb_feedback_id_external" class="feedback_details">
                <CRow>
                  <CCol cols="12" lg="12" class="pt-0">
                    <p class="mt-0 mb-1"><b>{{ $t('knowledge.Feedback_given_by') }}</b></p>
                    <div class="d-flex align-items-center">                      
                      <div v-if="activeFeedbackItem.feedback_creator" @click="openProfilePage(activeFeedbackItem.feedback_creator_name, activeFeedbackItem.feedback_creator);">                            
                        <userProfilePopover ref="userProfilePopover" mode="user" :userName="activeFeedbackItem.feedback_creator_name" :userProfileImage="activeFeedbackItem.feedback_creator_profile_image" :userIdExternal="activeFeedbackItem.feedback_creator" :senderIdExternal="null" popoverPlacement="bottom" class="user_image small mr-1"/>
                      </div>
                      <div class="flex-grow-1">
                        <span>{{ activeFeedbackItem.feedback_creator_name }}</span>
                      </div>
                    </div>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol cols="12" lg="12" class="pt-0">
                    <p class="mt-0 mb-1"><b>{{ $t('knowledge.Feedback_given_at') }}</b></p>
                    <span>{{ activeFeedbackItem.created | moment("YYYY-MM-DD HH:mm") }}</span>
                  </CCol>
                </CRow> 
                <CRow>
                  <CCol cols="12" lg="12" class="pt-0">
                    <p class="mt-0 mb-1"><b>{{ $t('knowledge.Reason_for_feedback') }}</b></p>
                    <b-tag class="info">{{$t('knowledge.feedback_' + activeFeedbackItem.feedback_type)}}</b-tag>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol cols="12" lg="12" class="pt-0 pb-0">
                    <p class="mt-0 mb-1"><b>{{ $t('common.Message') }}</b></p>
                    <div class="message_container">
                      <span>{{ activeFeedbackItem.message }}</span>
                    </div>
                  </CCol>
                </CRow>                                                
              </div>
              <div v-else>
                <CRow>            
                  <CCol cols="12" lg="12" class="pt-0 pb-0">
                    {{$t('knowledge.Select_feedback_to_view_details')}}
                  </CCol>
                </CRow>  
              </div>
            </CCol>
          </CRow>        
        </CCardBody>
        <CCardFooter v-if="activeFeedbackItem.kb_feedback_id_external">
          <CRow>
            <CCol cols="12" lg="12" class="pt-0 pb-0">
              <div class="d-flex justify-content-lg-end">
                <CButton v-if="activeFeedbackItem.feedback_status === 'open'"
                         color="primary"
                         @click="updateFeedbackStatus('rejected')">
                  <i class="fas fa-ban mr-1"/>{{ $t('common.Reject') }}
                </CButton>                
                <CButton v-if="modules.employee_loyalty_programme === 1 && checkPermission('loyalty.grantpoints')"
                         color="primary"
                         @click="openSidebarRight('grant_points');">
                  <i class="fas fa-gift mr-1"/>{{ $t('points.Grant_points') }}
                </CButton>                
                <CButton v-if="checkPermission('knowledge.library.edititem')"
                         color="primary"
                         v-bind:class="{'m-0' : activeFeedbackItem.feedback_status !== 'open'}"
                         @click="openSidebarRight('knowledge_item_details', { kb_intent_subcategory: null, kb_intent_id_external: activeFeedbackItem.kb_intent_id_external, back_to_preview: false })">
                  <i class="fas fa-pen mr-1"/>{{ $t('tooltip.Edit_item') }}
                </CButton>
                <CButton v-if="activeFeedbackItem.feedback_status === 'open'"
                         class="m-0"
                         color="primary"
                         @click="updateFeedbackStatus('done')">
                  <i class="fas fa-check mr-1"/>{{ $t('common.Done') }}
                </CButton>                              
              </div>
            </CCol>
          </CRow>
        </CCardFooter>
      </CCard>
    </CCol>

    <CCol cols="3" lg="3" md="12" v-if="knowledgeItem.kb_intent_id_external" class="knowledge_item">
      <CRow>
        <CCol cols="12" lg="12" class="pt-0 pb-0">
          <knowledgeItem :itemData="knowledgeItem" :showLikes="true" :navigatable="false" :preview="false" :scrollableContent="true"/>
        </CCol>
      </CRow>
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import QuickEdit from 'vue-quick-edit';
import Multiselect from 'vue-multiselect';

import noPermission from '@/components/common/noPermission.vue';
import userProfilePopover from '@/components/common/userProfilePopover.vue';
import userProfileAvatar from "@/components/common/userProfileAvatar.vue";

import knowledgeItem from '@/components/knowledge/knowledgeItem.vue';

export default {
  name: 'Service',
  components: {
    QuickEdit,
    Multiselect,
    noPermission,
    userProfilePopover,
    userProfileAvatar,
    knowledgeItem
  },
  data() {
    return {
      platformPermissions: [],
      platformPermissionsLoaded: false,      
      modules: { employee_loyalty_programme: 0 },
      apiBaseUrl: null,
      clientToken: null,      
      feedbackApiPath: null, 
      feedback: [],
      feedbackStatuses: [],
      showMyFeedbackItems: false,
      selectedStatuses: [{
        tag: 'open',
        label: 'Open'
      }],
      timeRange: 'all',
      timeRangeSelect: [
        {value: '90', label: this.$t('knowledge.Feedback_90_days')},
        {value: '365', label: this.$t('knowledge.Feedback_365_days')},
        {value: 'all', label: this.$t('knowledge.Feedback_all_time')},
      ],
      feedbackIdExternal: null,
      activeFeedbackItem: {},
      knowledgeItem: {},      
      knowledgeItemIdExternal: null,
    }
  },
  methods: {
    getFeedback() {
      let params = {};
      params.statuses = this.selectedStatuses;
      params.time_range = this.timeRange;

      (!this.showMyFeedbackItems) ? this.feedbackApiPath = '/v1/knowledge/feedback' : this.feedbackApiPath = '/v1/knowledge/feedback/my';

      axios.post(process.env.VUE_APP_API_URL + this.feedbackApiPath, params)
      .then(res => {
        this.feedback = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getFeedbackDetails(knowledgeFeebdackIdExternal, knowledgeItemIdExternal) {
      this.knowledgeFeebdackIdExternal = knowledgeFeebdackIdExternal;
      this.knowledgeItemIdExternal = knowledgeItemIdExternal;

      axios.get(process.env.VUE_APP_API_URL + '/v1/knowledge/feedback/' + this.knowledgeFeebdackIdExternal)
      .then(res => {
        // Set the active feedback item
        this.activeFeedbackItem = res.data.data;
        // Get the knowledge item details
        this.getKnowledgeItem();
      })
      .catch(err => {
        console.error(err); 
      });
    },
    updateFeedbackStatus(status) {
      let params = {};
      params = this.activeFeedbackItem;
      params.feedback_status = status;

      axios.put(process.env.VUE_APP_API_URL + '/v1/knowledge/feedback/' + this.knowledgeFeebdackIdExternal, params)
      .then(res => {
        this.getFeedback();
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getKnowledgeItem() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/knowledge/item/' + this.knowledgeItemIdExternal)
      .then(res => {
        this.knowledgeItem = res.data.data;       
      })
      .catch(err => {
        console.error(err); 
      });       
    },
    getFeedbackStatuses() {
      axios.get('v1/knowledge/feedback/statuses/multiselect')
      .then(res => {
        this.feedbackStatuses = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkModules() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/modules')
      .then(res => {      
        this.modules = res.data.data;   
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    } 
  },
  mounted: function(){
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');    
    // Check the modules
    this.checkModules();
    this.getPlatformPermissions();
    // Get the feeaback
    this.getFeedback();
    this.getFeedbackStatuses();   
  }
}
</script>
